<script lang="ts" setup>
	import useSizes from "~/composables/useSizes";
	import { Sizes } from "~/utils/enums";

	const props = withDefaults(
		defineProps<{
			size?: Sizes;
		}>(),
		{
			size: "small",
		}
	);

	const { size } = toRefs(props);

	const { isSizeSmall, isSizeLarge } = useSizes(size);
</script>

<template>
	<svg class="icon-contacts" v-if="isSizeLarge" width="72" height="65" viewBox="0 0 72 65" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M71.8292 64V57.5C71.8292 46.7304 63.0988 38 52.3292 38C48.2218 38 44.411 39.2699 41.2681 41.4385C42.0307 42.1167 42.7523 42.8399 43.4287 43.604C45.9966 41.9558 49.0513 41 52.3292 41C61.4419 41 68.8292 48.3873 68.8292 57.5V64H71.8292Z"
			fill="black"
		/>
		<circle cx="52.5" cy="22.5" r="12" stroke="black" stroke-width="3" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M48 60.5V65H51V60.5C51 46.4167 39.5833 35 25.5 35C11.4167 35 0 46.4167 0 60.5V65H3V60.5C3 48.0736 13.0736 38 25.5 38C37.9264 38 48 48.0736 48 60.5Z"
			fill="black"
		/>
		<circle cx="25.5" cy="16.5" r="15" stroke="black" stroke-width="3" />
	</svg>

	<svg class="icon-contacts" v-if="isSizeSmall" width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M23 19.5V21H21V19.5C21 17.0147 18.9853 15 16.5 15C16.0298 15 15.5765 15.0721 15.1504 15.2059C14.696 14.6358 14.1698 14.1254 13.5854 13.6885C14.4621 13.248 15.4521 13 16.5 13C20.0898 13 23 15.9101 23 19.5Z"
			fill="black"
		/>
		<circle cx="16.5" cy="7.5" r="3.5" stroke="black" stroke-width="2" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15 20.5V21H17V20.5C17 15.8056 13.1944 12 8.5 12C3.80558 12 0 15.8056 0 20.5V21H2V20.5C2 16.9101 4.91015 14 8.5 14C12.0899 14 15 16.9101 15 20.5Z"
			fill="black"
		/>
		<circle cx="8.5" cy="5.5" r="4.5" stroke="black" stroke-width="2" />
	</svg>
</template>

<style scoped lang="scss">
	.icon-contacts {
		rect,
		path,
		circle {
			transition: all 0.2s ease-out;
		}
	}
</style>
